<script lang="ts" setup>
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import type { BackgroundColorsPredefined, FeatureCard, IntroData } from '~/@types/cms';
    import 'swiper/css';

    declare interface FeatureSliderProps {
        features: FeatureCard[];
        intro?: IntroData;
        link?: Link;
        background?: BackgroundColorsPredefined;
    }

    defineProps<FeatureSliderProps>();

    const md = screens('md');
    const lg = screens('lg');

    const { setMainSwiper, swipeLeft, swipeRight, isBeginning, isEnd, setControls, onSlideFocus } = useGallery();
</script>
<template>
    <mol-section
        :intro="intro"
        center
        :background="background"
        class="org-feature-slider overflow-hidden">
        <div class="col-span-2 md:col-span-12">
            <nuxt-error-boundary @error="() => {}">
                <swiper
                    class="relative !overflow-visible"
                    :slides-per-view="1"
                    :prevent-clicks="true"
                    :breakpoints="{
                        [0]: {
                            slidesPerView: 1.05,
                        },
                        [md]: {
                            slidesPerView: 2.05,
                        },
                        [lg]: {
                            slidesPerView: 3.05,
                        },
                    }"
                    :centered-slides="features.length === 1"
                    :space-between="16"
                    @slide-change="setControls"
                    @swiper="setMainSwiper">
                    <swiper-slide
                        v-for="(feature, index) in features"
                        :key="feature.id"
                        @focusin="() => onSlideFocus(index)">
                        <mol-feature-card
                            :data="feature"
                            :component-index="index" />
                    </swiper-slide>
                    <mol-swiper-navigation
                        :is-beginning="isBeginning"
                        :is-end="isEnd"
                        :swipe-left="swipeLeft"
                        :swipe-right="swipeRight"
                        :link="link"
                        theme="wider" />
                </swiper>
            </nuxt-error-boundary>
        </div>
    </mol-section>
</template>

<style scoped>
    .slide {
        /*
         * KEEP IN SYNC WITH swiper breakpoints, swiper space between and grid gutter to avoid width jump before js loads
         * :breakpoints="{}"
         * :space-between="16"
         * gutter also see: components/atm/Grid.vue
         * swiper slide width: (swiperWidth - gutter) - (spaceBetween * slidesPerView) / (slidesPerView + swipeOffset)
         */
        --swiper-width: 100vw;
        --gutter: 48px;
        --swiper-offset: 0.05;
        --slides-per-view: 1;
        --space-between: 16px;
        width: calc(
            (var(--swiper-width) - var(--gutter) - (var(--slides-per-view) * var(--space-between))) / (var(--slides-per-view) + var(--swiper-offset))
        );
        @screen md {
            --gutter: 64px;
            --slides-per-view: 2;
        }
        @screen lg {
            --swiper-width: 960px;
            --gutter: 0px;
            --slides-per-view: 3;
        }
        @screen xl {
            --swiper-width: 1200px;
        }
    }
</style>
