<script setup lang="ts">
    import type { FeatureSliderData } from '~/@types/cms';
    import type { IWidgetProps } from '~/composables/widgets';

    const props = defineProps<IWidgetProps<FeatureSliderData>>();
    const { data, getValueSequence } = useWidget(props);

    const features = computed(() => {
        return getValueSequence(data?.value);
    });
</script>
<template>
    <org-feature-slider
        :features="features"
        :intro="data.intro"
        :link="data.link"
        :background="data?.style?.background_color"
        class="cms-feature-slider" />
</template>
